<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe pagination :max-items="20" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import { db } from '@/firebase/firebaseConfig'

import ubigeo from '../../data/ubigeo/ubigeo.json'

export default {
  name: 'ImporSales',
  components: {
    ImportExcel
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      ubigeo: ubigeo,
      clients: [],
      users: [],
      sales: []
    }
  },
  async created () {
    try {
      // Get clients
      const querySnap = await db.collection('clients').orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          ...doc.data()
        }
        this.clients.push(client)
      })
      // Get users
      let snapShot = await db.collection('users').get()
      snapShot.forEach((u) => {
        let user = {
          id: u.id,
          ...u.data()
        }
        this.users.push(user)
      })
      // Get sales
      const querySnapSales = await db.collection('sales')
          .orderBy('createdAt', 'desc').get()
      querySnapSales.forEach((doc) => {
        let sale = {
          id: doc.id,
          ...doc.data()
        }
        this.sales.push(sale)
      })
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async importData () {
      try {
        this.initProgress = true
        // Write products
        let batch = db.batch()
        let count = 0
        const batchs = []
        batchs.push(batch)
        let flag = 0
        for await (let data of this.tableData) {
          count++
          const sale = this.sales.find((s) => s.idMysql === data.idComprobanteVenta)
          console.log(sale)
          console.log(data.idComprobanteVenta)
          if (sale) {
            const docRef = db.collection('sales').doc(sale.id)
            if (count < 249) {
              batchs[flag].update(docRef, {
                status: (data.estado === 'Aceptado' || data.estado === 'Cancelado') ? 'send' : 'rejected',
              })
            } else {
              flag++
              count = 0
              batchs.push(db.batch())
            }
          }
        }

        // Save in firestore
        for await (let b of batchs) {
          await b.commit()
        }

        // End
        this.$vs.notify({
          color: 'success',
          title: 'Importacion',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    }
  }
}
</script>
